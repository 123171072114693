export interface Route {
  name: string;
  getPath: (...args: string[]) => string;
}

export type Pages =
  | 'Home'
  | 'CreateCampaign'
  | 'ShowCase'
  | 'Campaign'
  | 'CreditsAdded'
  | 'ContentCollection'
  | 'Messages'
  | 'AddCredits';

// type NavConfig = Record<Pages, Required<Route>>;

const ROUTES = {
  Home: {
    name: 'Auth',
    getPath: () => '/',
  },
  CreateCampaign: {
    name: 'New Campaign',
    getPath: () => '/campaigns/create',
  },
  ShowCase: {
    name: 'Content Showcase',
    getPath: () => `${process.env.NEXT_PUBLIC_LEGACY_URL}/campaigns/showcase`,
  },
  Campaign: {
    name: 'Campaign',
    getPath: (campaignId: string) =>
      `${process.env.NEXT_PUBLIC_LEGACY_URL}/campaigns/${campaignId}/summary`,
  },
  CreditsAdded: {
    name: 'Credits Added',
    getPath: () => '/checkout/credits-added',
  },
  ContentCollection: {
    name: 'Content Collection',
    getPath: (collectionId: string) => `/content/${collectionId}`,
  },
  Messages: {
    name: 'Messages',
    getPath: (campaignId: string, messageId: string) =>
      `${process.env.NEXT_PUBLIC_LEGACY_URL}/campaigns/${campaignId}/messages/${messageId}`,
  },
  AddCredits: {
    name: 'Add Credits',
    getPath: () => '/credits/add-credits',
  },
} as const;

export default ROUTES;
